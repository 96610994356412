<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light mask rgba-white-strong fixed-top scrolling-navbar" [containerInside]="true">

  <mdb-navbar-brand><a class="navbar-brand" href="#">
    <img src="/assets/logo.png" alt="" class="img-fluid rounded-circle z-depth-0" style="width:270px">
  </a></mdb-navbar-brand>

  <links>
    <ul mdbScrollSpy="scrollspy1" class="navbar-nav ml-auto">
      <li class="nav-item">
        <a mdbScrollSpyLink="home" class="nav-link waves-light" mdbWavesEffect>Home<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a mdbScrollSpyLink="about" class="nav-link waves-light" mdbWavesEffect>About us</a>
      </li>
      <li class="nav-item">
        <a mdbScrollSpyLink="data" class="nav-link waves-light" mdbWavesEffect>Data Analytics</a>
      </li>
      <li class="nav-item">
        <a mdbScrollSpyLink="automation" class="nav-link waves-light" mdbWavesEffect>Automation</a>
      </li>
      <li class="nav-item">
        <a mdbScrollSpyLink="machine" class="nav-link waves-light" mdbWavesEffect>Machine Learning</a>
      </li>
      <li class="nav-item">
        <a mdbScrollSpyLink="financial" class="nav-link waves-light" mdbWavesEffect>Financial Systems</a>
      </li>
    </ul>
  </links>
</mdb-navbar>

<div mdbScrollSpyElement="scrollspy1" id="home" class="view intro jarallax" style="background-image: url(/assets/landingBackground.png); background-attachment: fixed; height: 1200px;">
  <div class="full-bg-img mask rgba-white-light">
    <div class="container">
      <div class="d-flex align-items-center d-flex justify-content-center" style="height: 1200px">
        <div class="row mt-5">
          <div class="col-md-12 wow fadeIn mb-3">
            <div class="intro-info-content text-center">
              <h3 class="h3 display-4 mb-2 wow fadeInDown" data-wow-delay="0.3s">Automate and streamline your business</h3>

              <h5 class="font-up mb-3 mt-1 font-bold wow fadeIn" data-wow-delay="0.4s">Technology consulting focusing on financial application development, automation and integration.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mdbScrollSpyElement="scrollspy1" id="about" class="container">
  <div class="row">
    <div class="col-md-12 text-center mb-5">
      <h2 class="h2 font-bold grey-text my-5">About us</h2>
      <p class="grey-text text-left">We provide software solutions to businesses to help optimize and streamline workflow. 
        In addition to business software development we also develop apps for businesses and end consumers that 
        range from specialist financial tools to productivity management.         
        <br>
        <br>
        We have over twelve years of experience in a diverse range
        of industries including Automotive, Financial Services, Mining, Technology and Banking.
        <br>
        <br>        
        The advancement of technology has made it possible for 
        any size business to benefit from a level of automation that was previously only within reach of larger, well-established 
        companies. 
        Whether you're a large corporation or a small restaurant owner, we can help you exploit the opportunities and efficiencies
        that are emerging as the 4th industrial revolution continues to unfold.
      </p>
    </div>
  </div>
</div>

<div mdbScrollSpyElement="scrollspy1" id="data" class="view intro jarallax" style="background-image: url(/assets/charts.jpg); background-attachment: fixed; background-size: cover; min-height: 700px">
  <div class="full-bg-img mask rgba-black-strong">
    <div class="container flex-center">
      <h2 class="h2 display-3 mb-2 wow fadeInDown grey-text" data-wow-delay="0.3s">Data <a class="white-text font-bold">Analytics</a></h2>
    </div>      
  </div>
</div>

<section class="container">

  <div class="row">
    <div class="col-md-12 text-center mb-5">
      <h2 class="h1 font-bold cyan-text lighten-3 my-5"></h2>

      <p class="grey-text text-left" >
        In all modern organisations, data resides in many different systems and formats, often in overwhelming volumes. 
        Extracting meaningful information from multiple, disparate sources can be a daunting undertaking, but it is an imperative excercise 
        for any enterprise wishing to remain ahead in a highly competitive environment. 
        <br>
        <br>
        Allow us to help you empower your monitoring and 
        decision-making processes with real-time visibility and predictability. Whether your needs are simple reporting and dash-boarding 
        or the implementation of advanced prediction models, with our extensive expertise with analytics packages and backgrounds in 
        S.T.E.M and A.I, we are able to provide a diverse and comprehensive array of solutions. 
        <br>
        <br>
        Greenlight your data vision!
      </p>
    </div>
  </div>  

  <div class="row pt-2">
    

    <div class="col-lg-5 text-center text-lg-left"> 
      <h5 class="h5 cyan-text lighten-3 text-center my-5">New COVID-19 cases</h5>     
      <div style="display: block">
        <canvas mdbChart
            [chartType]="chartType"
            [datasets]="chartDatasets"
            [labels]="chartLabels"
            [colors]="chartColors"
            [options]="chartOptions"
            [legend]="true"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)">
        </canvas>
      </div>
      <h2 class="h1 font-bold cyan-text lighten-3 my-5"></h2>
    </div>

    <div class="col-lg-7">
      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="chart-pie" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Data visualisation</h5>
          <p class="grey-text text-left">
            The usefulness of your data is heavily influenced by how it is visualised and consumed. Whether you need a live 
            dashboard or a scheduled report we can bring it to life using a variety of off-the-shelf applications like Tableau
            Power BI, Qlikview, Google Data Studio and others. No visualisation software? No problem we build that too!
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="tools" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Data extraction and preparation</h5>
          <p class="grey-text text-left">
            Data preparation involves collating, cleaning and blending data from various systems and formats into a single data 
            source ready for visualisation. We will help you select and implement one of many ETL packages that suits your 
            needs. We have experience with a variety of options, from powerfull, well-equipped analytics solutions, to community-supported
            open-source integration software like Talend. If no off-the-shelf software has connectors for your data sources, we have extensive
            experience building custom connectors.
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="gem" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Process mining</h5>
          <p class="grey-text text-left">
            Data mining is a set of techniques that extract data and metadata from your systems and create process maps that allow you to
            visualise your processes. This is helpful for gaining a comprehensive perspective of your processes identifying bottlenecks and
            performing what-if analysis. Not only do we have experience with software like Celonis and packages like bupaR, we have built 
            custom solutions that allow teams to view and explore their processes.
          </p>
        </div>
      </div>

    </div>
  </div>  
</section>








<div mdbScrollSpyElement="scrollspy1" id="automation" class="view intro jarallax" style="background-image: url(/assets/automation.jpg); background-attachment: fixed; background-size: cover; min-height: 700px">
  <div class="full-bg-img mask rgba-black-strong">
    <div class="container flex-center">
      <h2 class="h1 display-3 mb-2 wow fadeInDown grey-text" data-wow-delay="0.3s">Automation</h2>
    </div>    
  </div>
</div>

<section class="container">

  <div class="row">
    <div class="col-md-12 text-center mb-5">
      <h2 class="h1 font-bold cyan-text lighten-3 my-5"></h2>

      <p class="grey-text text-left" >
        Frederick Taylor beleived that greater efficiency is not achieved by making people work harder, but rather, optimizing the
        way their work is done in the first place. A modern organization 
        that commits much of its staff to repetitive, mindless tasks will never be as efficient as it could be. For all our strengths,
        we humans can get bored, tired and prone to error. It's time to supplement your workforce with a digital one and
        to use your human resources where they are most effective and can feel inspired. A simple survey of your team will reveal
        how many of them have already identified automation opportunities whenever they say: "if only this could be done automatically". 
        Let us help you turn these possibilities into realities.
      </p>
    </div>
  </div>  

  <div class="row pt-2">

    <div class="col-lg-7">

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="robot" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Robotic Process Automation</h5>
          <p class="grey-text text-left">
            RPA has taken off in recent years as a means of automating tasks by performing them the way a user would through the UI.
            We work with Automation Anywhere, UI Path, Blue prism and others. We believe that, for the best results, RPA should not be
            implemented in isolation but should form part of broader ecosystem of tools. Let us walk with you during your RPA journey
            and implement your first bots with you.
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="tools" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Custom automation</h5>
          <p class="grey-text text-left">
            With over 15 years software development experience, we have helped countless teams automate all mannor of generic and 
            niche processes. Our methodology calls for an holistic examination of the process in context and identifying any 
            improvements and efficiencies that can be brought to bear on the whole activity. We also build within the confines of
            your particular environment and internal ordinances. We understand that time is money and will not reinvent the wheel if
            subcomponents can be handled by prebuilt libraries or software. 
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="user-cog" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Workflows</h5>
          <p class="grey-text text-left">
            Whether you require a simple daily tasklist reminders, more complex approval activities or even daily remittances to be 
            sent automatically, various low-code applications exist that allow you to automate common processes with ease. Let us help
            you implement Business Process Management software like BonitaBPM or Pega, or take advantage of your existing Office 365 suite
            to implement workflows in SharePoint.
          </p>
        </div>
      </div>



    </div>

    <div class="col-lg-5 text-center text-lg-left">      
      <img src="/assets/gears.png" alt="" class="img-fluid z-depth-0">
      <h2 class="h1 font-bold cyan-text lighten-3 my-5"></h2>
    </div>

  </div>  
</section>




<div mdbScrollSpyElement="scrollspy1" id="machine" class="view intro jarallax" style="background-image: url(/assets/machinelearning.jpg); background-attachment: fixed; background-size: cover; min-height: 700px">
  <div class="full-bg-img mask rgba-black-strong">
    <div class="container flex-center">
      <h2 class="h1 display-3 mb-2 wow fadeInDown grey-text" data-wow-delay="0.3s">Machine <a class="white-text font-bold">Learning</a></h2>
    </div>    
  </div>
</div>

<section class="container">

  <div class="row">
    <div class="col-md-12 text-center mb-5">
      <h2 class="h1 font-bold cyan-text lighten-3 my-5"></h2>

      <p class="grey-text text-left" >
        Machine learning has become something of a buzzword of late. It can often seem like hype but is it really something your organisation
        can benefit from? The answer is YES! The power of AI has brought with it the promise of automating not only menial and repetitive
        tasks, but also those requiring skill and nuance. We have experience building and productionalizing real life solutions that save 
        time and money.

      </p>
    </div>
  </div>  

  <div class="row pt-2">

    <div class="col-lg-5 text-center text-lg-left">      
      <img src="/assets/brain.png" alt="" class="img-fluid z-depth-0">
      <h2 class="h1 font-bold cyan-text lighten-3 my-5"></h2>
    </div>

    <div class="col-lg-7">
      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="random" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Automated helpdesk request routing</h5>
          <p class="grey-text text-left">
            Does your helpdesk complain that requests are being routed incorrectly? Using a neural network we were able to have a machine
            read through an email and correctly assign it to the right team. One step closer to one-touch request handling.
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="comments" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Chatbots</h5>
          <p class="grey-text text-left">
            With the aid of powerful natural language processing libraries, our chatbots are able to understand and respond to a large variety
            of frequently asked questions. Lower your SLAs and let a chatbot be your first line of contact with customers, employees and other inquirers, 
            enabling your team to spend its time on more complex issues.          
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="search-dollar" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Fraud detection</h5>
          <p class="grey-text text-left">
            Although detecting fraud amongst your transactions is usually something we would handle using well-defined rulesets, given enough
            data and history, a model can be trained to identify possible fraudulent transactions where reasons for flagging these items in
            the past cannot be clearly defined.
          </p>
        </div>
      </div>

    </div>
  </div>  

  <h1 class="h1 font-bold cyan-text lighten-3 my-5"></h1>

</section>






<div mdbScrollSpyElement="scrollspy1" id="financial" class="view intro jarallax" style="background-image: url(/assets/financialsystem.jpg); background-attachment: fixed; background-size: cover; min-height: 700px">
  <div class="full-bg-img mask rgba-black-strong">
    <div class="container flex-center">
      <h2 class="h2 display-3 mb-2 wow fadeInDown grey-text" data-wow-delay="0.3s">Financial <a class="white-text font-bold">Systems</a></h2>
    </div>    
  </div>
</div>

<section class="container">

  <div class="row">
    <div class="col-md-12 text-center mb-5">
      <h2 class="h1 font-bold cyan-text lighten-3 my-5"></h2>
      <p align="justify" class="px-5 mb-5 pb-3 lead grey-text text-center container" >
      </p>
    </div>
  </div>  

  <div class="row pt-2">
    
    <div class="col-lg-7">

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="thumbs-up" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Compliance</h5>
          <p class="grey-text text-left">
            Compliance can be a mundane task but is necessary to ensure problems don't show up later. Why not automate these? We have 
            experience automating simple tasks like keeping employee spending in line, to conformity with complex
            federal requirements like Sarbanes-Oxley or validation required by SEPA-clearing.
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="server" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">ERP integration</h5>
          <p class="grey-text text-left">
            Your enterprise resource planning application touches every part of your organisation and is the cornerstone of your finance operation.
            While these applications can do a lot, you'll find they can't do everything when your needs are niche and particular to your organisation
            and processes. This is where we come in! We have experience building add-ons that seemlessly integrate with your application. Whether
            you use an enterprise-level ERP like Oracle E-Business Suite or smaller cloud-based systems like Xero, we've got you covered!
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="equals" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Reconciliations</h5>
          <p class="grey-text text-left">
            Bank reconciliations, supplier account reconciliations, and many others are frequent items on any accountant's todo list.
            Most of the steps involved can be automated. Let us automate the extraction of data from all sources and comparison stages and
            set up rules for known exceptions. Receive a completed reconciliation in your mailbox each morning and only intervene when there are
            unexpected reconciling items.
          </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="chart-line" size="lg" class="cyan-text lighten-3"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Derivatives pricing models</h5>
          <p class="grey-text text-left">
            We implement a wide variety of pricing models and not simply those with closed form solutions but also those requiring simulation.
            Go beyond Excel and have these available to you through your own web portal, via API, Office add-in or standalone desktop application.
          </p>
        </div>
      </div>

    </div>

    <div class="col-lg-5 text-center text-lg-left">      
      <img src="/assets/servers.jpg" alt="" class="img-fluid z-depth-0">

      <h2 class="h1 font-bold cyan-text lighten-3 my-5"></h2>
    </div>

  </div>  

  <h1 class="h1 font-bold cyan-text lighten-3 my-5"></h1>
</section>





<footer class="page-footer font-small cyan pt-4">
  <div class="container">
    <ul class="list-unstyled list-inline text-center py-2">
      <li class="list-inline-item">
        <h5 class="mb-1">Contact us today at <a href="mailto:info@logichub.tech">info@logichub.tech</a></h5>
      </li>
    </ul>    
  </div>
  <div class="footer-copyright text-center py-3">© 2022 Copyright:
    <a href="https://www.logichub.tech/"> LogicHub, LLC</a>
  </div>
</footer>
