import {AfterViewInit, Component} from '@angular/core';
import 'jarallax';
declare var jarallax: any;
@Component({
selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {


  // tslint:disable-next-line:no-inferrable-types
  public chartType: string = 'line';

  public chartDatasets: Array<any> = [
    { data: [26543, 21352, 48529, 26857, 22541, 24132, 27326], label: 'This week' },
    { data: [31667, 30833, 32922, 24601, 28065, 37289, 17588], label: 'Last week' }
  ];

  public chartLabels: Array<any> = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];

  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(128,222,234, .5)',
      borderColor: 'rgba(128,222,234, .9)',
      borderWidth: 2,
    },
    {
      backgroundColor: 'rgba(20, 20, 20, .2)',
      borderColor: 'rgba(20, 20, 20, .7)',
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }



  ngAfterViewInit() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2
    });
  }
}
